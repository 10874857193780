@import "../../sass/utilities/manifest";

.graph-container {
  background: $background;
  border-radius: 10px;
  width: 100%;
  padding: 25px;
  // margin-bottom: 25px;

  display: flex;
  flex-direction: column;

  .graph-top-bar {
    padding: 7.5px 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    .graph-name {
      font-size: 19px;
    }
  }

  .legend-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: -10px;
    .legend-item {
      margin-top: 10px;
      display: flex;
      padding: 5px 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      margin-right: 10px;

      .legend-item--fill {
        border-radius: 5px;
      }
    }
  }

  .more {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }
  .more-icon {
    width: 17.5px;
    height: 17.5px;
    background-image: url("../../assets/icons/More.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    margin-right: 7.5px;

    &:hover {
      cursor: pointer;
      background-image: url("../../assets/icons/MorePurple.svg");
    }
  }
}
