// ==========================================================================
// Utilities
// Define functions and mixins at this partial.
// ==========================================================================

//Font Imports

//Provide url in '$font-url--google' variable from '_config.scss' partial
@if variable-exists(font-url--google){
  @import url($font-url--google); // CSS font import from Google CDN
}

@if variable-exists(typefaces) {
  @each $file, $option in $typefaces {
    @font-face {
      font-family: map-get(map-get($typefaces, $file), family);
      font-style: map-get(map-get($typefaces, $file), style);
      font-weight: map-get(map-get($typefaces, $file), weight);
      src: url('../fonts/#{$file}.ttf') format('truetype'),
           url('../fonts/#{$file}.woff') format('woff'),
           url('../fonts/#{$file}.woff2') format('woff2');
    }
  }
}

// Functions

// Add pixel unit
// $value     - Value for adding px unit
// @returns   - number
@function addPxUnit($value){
  @if unitless($value) {
    $value: $value * 1px;
  }
  @return $value;
}

// Convert pixels to em
// $target    - Value to be converted into em
// $context   - Default: Base font size. Base font size is usually 16px depending on browser default font size
// @returns   - number
@function em($target, $context: $base__font-size){
  $target: addPxUnit($target);
  $context: addPxUnit($context);
  @return($target/$context) * 1em;
}

// Convert pixels to rem
// $target    - Value to be converted into rem
// $context   - Default: Base font size. Base font size is usually 16px depending on browser default font size
// @returns   - number
@function rem($target, $context: $base__font-size){
  $target: addPxUnit($target);
  $context: addPxUnit($context);
  @return $target/$context * 1rem;
}

// Convert pixels to percentage
// $target    - Value to be converted into percentage
// $context   - Context on which target needs to be relative to for percentage conversion
// @returns   - number
@function pntg($target, $context){
  @return ($target/$context) * 100%;
}

// Use color palette
// $palette   - Map required under $palettes map($palettes map is defined under _config.scss partial)
// $shade     - Key of provide $palette map
// base       - Default key of $shade under $palette map
// @returns   - map
@function palette($palette, $shade: base){
  @return map-get(map-get($palettes, $palette), $shade);
}

// Calculate if number is even or not
// $number    - Number to be verified
// @returns   - boolean
@function even($number){
  @if $number%2 == 0{
    @return true;
  }
  @else{
    @return false;
  }
}

// Mixins

// Map background color to elements
// $map    - Map containg key value pair for background color
// $theme  - Key of provided map from $map
// $color  - Value of provided map from $map
// @usage  - `.message {@include bg-colors($ui-colors);}`
@mixin bg-colors($map){
  @each $theme, $color in $map{
    &--#{$theme}{
      background: $color;
    }
  }
}

//BEM(Block Element Modifier) Naming Convention
// For Element
// $element  - Just the element name for the parent block (doesn't require the parent Block name)
// @usage    - `.Nav {@include e(item){...}}`
@mixin e($element){
  &__#{$element}{
    @content;
  }
}

// For Modifier
// $modifier  - Just the modifier name for the parent block or element
// @usage     - `.Nav {@include e(item) {@include m(active) {...}}}`
@mixin m($modifier){
  &--#{$modifier}{
    @content;
  }
}

// Media Queries
// $break  - RWD break point values, change them in _config.scss. Default: bootstrap grid
// @usage  - `.Nav {@include mq(sm) {...}}`
@mixin mq($break){
  @if $break == "xs" {//lowest breakpoint value
    @content;
  }
  @else if $break == "sm" {// phone
    @media(min-width: $sm-device){
      @content;
    }
  }
  @else if $break == "md" {// tablet
    @media(min-width: $md-device){
      @content;
    }
  }
  @else if $break == "lg" {// laptop and desktop
    @media(min-width: $lg-device){
      @content;
    }
  }
  @else if $break == "xl" {// High res desktop and TVs
    @media(min-width: $xl-device){
      @content;
    }
  }
  @else {
    @error "No value could be retrieved for '#{$break}'";
  }
}

// Hover mixin
// @usage  - `button {@include hover {...}}`
@mixin hover {
  &:hover,
  &:active,
  &:focus {
    @extend %hover;
    @content;
  }
}

// Ellipsis mixin
// $width  - Value of width or max value of cutoff point in px
// @usage  - `.Text--info {@include ellipsis(120px) {...}}`
@mixin ellipsis($width){
  @extend %ellipsis;
  width : $width;
}

//Turn px in rem with px fallback
@mixin rem($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
}

// Pseudo element mixin
// $el         - provide "before" or "after" string
// $el-width   - Width for the pseudo element in px
// $el-height  - Height for the pseudo element in px
// @usage      - `.dropdown {@include psuedo-el('before') {...}}`
@mixin psuedo-el($el, $el-width: null, $el-height: null){
  @if $el == 'before' or $el == 'after'{
    &:#{$el}{
      @extend %pseudos;
      width: $el-width;
      height: $el-height;
      @content;
    }
  }
  @else{
    @warn "#{$el} is not a valid pseudo-element";
  }
}
 

.no-padding {
  padding: 0px !important;
}