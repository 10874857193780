// ==========================================================================
// Remove this file for your project.
// This is just an example sass file for demo. Please remove this file on
// your project.
// ==========================================================================
$bg-code: #525a96;
$bg-gray: #f5f5f5;
$pink: #df2771;

%tree-pseudo{
  content: "";
  position: absolute;
  background: $white;
  left: -20px;
  border-radius: 8px;
}
.example-doc{
  header{
    border-bottom: 1px solid $border-color;
    color: $white;
    font-weight: bold;
    background: #FF512F;

    @include themify(theme-type(color)){
      background: -webkit-linear-gradient(to right, theme-property(header-bg-left), theme-property(header-bg-right));
      background: linear-gradient(to right, theme-property(header-bg-left), theme-property(header-bg-right));
    }

    @include themify(theme-type(size)){
      padding: theme-property(headerPadding);
    }

    h1{
      line-height: 52px;
      text-transform: uppercase;
      display: inline-block;
      margin-bottom: 6px;
      letter-spacing: 2px;

      @include themify(theme-type(size)){
        font-size: theme-property(headerHeadingFont);
      }

      &:after{
        content: "";
        width: 50%;
        height: 2px;
        margin-top: 5px;
        background: $white;
      }
    }
    .sub-header{
      font-style: italic;
      line-height: 24px;
      margin-bottom: 20px;

      @include themify(theme-type(size)){
        font-size: theme-property(headerSubheadingFont);
      }
    }
    p{
      line-height: 24px;
      margin: 0;
      letter-spacing: 1px;

      @include themify(theme-type(size)){
        font-size: theme-property(headerParagraphFont);
      }
    }
  }
  p{
    margin-bottom: 10px;
  }
  main{
    background: $bg-gray;
    letter-spacing: 1px;

    @include themify(theme-type(color)){
      background: theme-property(main-bg);
    }
  }
  .tree{
    padding: 10px 20px;
    border: 2px solid;
    color: $white;
    border-radius: 2px;
    list-style: none;
    font-weight: bold;
    position: relative;

    @include themify(theme-type(color)){
      background: theme-property(tree-bg);
      border-color: theme-property(tree-border);
    }

    ul{
      position: relative;
      padding-left: 20px;
    }
    li{
      position: relative;
      padding-bottom: 2px;
      &:before{
        @extend %tree-pseudo;
        width: 2px;
        height: 100%;
        top: 0;
      }
      &:last-child:before{
        height: 50%;
      }
      &:after{
        @extend %tree-pseudo;
        width: 15px;
        height: 2px;
        top: 9px;
      }
    }
    > li{
      &:before, &:after{
        background: none;
      }
    }
  }

  pre{
    font-family: "Source Sans pro", Arial, sans-serif;
    padding: 10px;
    border: 2px solid;
    color: $white;
    border-radius: 2px;
    list-style: none;
    font-weight: bold;
    max-width: 800px;
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 10px;
      
    @include themify(theme-type(color)){
      background: theme-property(pre-bg);
      border-color: theme-property(pre-border);
    }

    @include themify(theme-type(size)){
      font-size: theme-property(preComponentFont);
    }
  }

  .Block{
    border-bottom: 1px solid $border-color;

    @include mq(sm){    
      @include themify(theme-type(size)){
        padding: theme-property(componentPadding);
      }
    }

    @include e(segment){
      border-bottom: 1px solid $border-color;
    
      @include themify(theme-type(size)){
        padding: theme-property(blockSegmentPadding);
      }

      &:last-child{
        border-bottom: none;
      }
    }
    @include e(code){
      margin-top: 15px;
      span{
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 14px;
      }
    }
    @include e(section) {
      margin-top: 24px;
    }
    @include e(form-group) {
      display: inline-block;
      width: 140px;
      margin-right: 18px;

      label {
        font-size: $default-font-size;
        font-weight: bold;
      }
    }
    h1,h2{
      display: inline-block;
      &:after{
        content: "";
        width: 100%;
        height: 4px;
        margin-top: 3px;

        @include themify(theme-type(color)){
          background: theme-property(heading-underline-bold);
        }
      }
    }
    h1{
      margin-bottom: 30px;
    }
    h2{
      margin-bottom: 20px;
      
      @include themify(theme-type(color)){
        color: theme-property(heading);
      }

      @include themify(theme-type(size)){
        font-size: theme-property(componentHeadingFont);
      }
    }
    h3{
      margin-bottom: 10px;
      display: inline-block;

      @include themify(theme-type(color)){
        color: theme-property(heading);
      }

      @include themify(theme-type(size)){
        font-size: theme-property(componentSubheadingFont);
      }

      &:after{
        content: "";
        width: 100%;
        height: 1px;
        margin-top: 3px;
      
        @include themify(theme-type(color)){
          background: theme-property(heading-underline-normal);
        }
      }
    }
    p{
      @include themify(theme-type(size)){
        font-size: theme-property(componentParagraphFont);
      }
    }
  }

  .Box{
    // For mobile devices of size less than 768px put code here
    width: 20px;
    height: 20px;
    background: $red;
    transition: width 0.3s ease-out, height 0.3s ease-out;
    margin-right: 10px;
    float: left;
    @include mq(sm){// For devices of size greater than 768px(tablets) put code here
      width: 50px;
      height: 50px;
      background: $blue;
    }
    @include mq(md){// For devices of size greater than 992px(laptops) put code here
      width: 70px;
      height: 70px;
      background: $green;
    }
    @include mq(lg){// For devices of size greater than 1200px(desktops and high-res TVs) put code here
      width: 100px;
      height: 100px;
      background: $yellow;
    }
  }
  .btn{
    padding: 10px 15px;
    border: 0;
    border-radius: 2px;
    color: $white;
    cursor: pointer;
    font-weight: bold;
    @include bg-colors($ui-colors);
  }
  .dotted{
    @include themify(theme-type(size)){
      margin-top: theme-property(dottedMarginTop);
    }
  }
  %palette-props{
    padding: 6px;
    color: $white;
    text-align: center;
  }
  .gray{
    @include m(base){
      @extend %palette-props;
      background: palette(gray-shades);
      color: $black;
    }
    @include m(dark){
      @extend %palette-props;
      background: palette(gray-shades, dark);
      color: $black;
    }
    @include m(light){
      @extend %palette-props;
      background: palette(gray-shades, light);
      color: $black;
    }
  }
  .blue{
    @include m(base){
      @extend %palette-props;
      background: palette(blue-shades);
    }
    @include m(dark){
      @extend %palette-props;
      background: palette(blue-shades, dark);
    }
    @include m(light){
      @extend %palette-props;
      background: palette(blue-shades, light);
    }
  }
  .Palette{
    @include e(wrap){
      margin-bottom: 20px;
      @include mq(sm){
        width: 300px;
        float: left;
        margin-right: 20px;
      }
    }
  }
  .snippet {
    padding: 12px 16px;
    border-radius: 4px;
    box-shadow: 0 1px 12px -2px rgba(0, 0, 0, 0.15);
    margin-top: 16px;

    @include themify(theme-type(color)) {
      background: theme-property(snippet-bg);
    }

    @include e(title) {
      font-size: 24px;
    }

    @include e(paragraph) {
      font-size: 16px;
    }
  }
  footer{
    padding: 15px 0;

    @include themify(theme-type(color)){
      background: theme-property(footer-bg);
    }

    color: $white;
    font-size: 14px;
    font-weight: bold;
    a{
      color: $white;
      font-weight: bold;
    }
  }
  .highlight {
    display: inline-block;
    padding: 0 5px;
    border-radius: 4px;
      
    @include themify(theme-type(color)){
      background: theme-property(highlight);
    }
  }
}
