// ==========================================================================
// Base
// A Base rule is applied to HTML element using an element selector, a
// descendent selector, or a child selector, along with any pseudo-classes.
// We cannot reference any element defined here by their respective class or ID name.
// This defines the default styling for how that element should look.
// Changes in style other than default should be overwritten using other partials.
// ==========================================================================

// Reset CSS
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-family--primary;
  // Global Clearfixing
  @if $grid-type == 'float'{
    &:after{
      clear: both;
      content: "";
      display: block;
    }
  }
}

// Base Code
html, body{
  font-size: 100%;
}
body{
  -webkit-font-smoothing: antialiased;
  color: $color-text;
  font-family: $font-family--primary;
  text-rendering: optimizeLegibility;
}
input {
  font-family: $font-family--primary;
}
a{
  outline: none;
  color: $color-link;
  text-decoration: none;
  transition: color .2s;
}
a, span, strong {
  &:after{
    @extend %clearfix-none;
  }
}
ul,ol{
  list-style: none;
}
h1,h2,h3,h4,h5,h6{
  font-family: $font-family--primary;
  margin: 0;
}
h1{
  font-size: $head1-font-size;
}
h2{
  font-size: $head2-font-size;
}
h3{
  font-size: $head3-font-size;
}
img{
  max-width: 100%;
  vertical-align: top;
}
select{
  border: 1px solid $gray;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 4px;
  outline: none;
  color: #ffffff;
  width: 100%;

  @include themify(theme-type(color)) {
    background-color: theme-property(select-bg);
  }

  @include themify(theme-type(size)) {
    font-size: theme-property(selectFontSize);
    padding: theme-property(selectPadding);
  }

  &:focus{
    outline: none;
    border-color: $light-blue;
  }
}
option {
  background-color: $white;
  color: $black;
}
.row {
  margin: 0 !important;
}