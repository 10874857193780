.btn {
    padding: 8px 12px;
    border: 0;
    border-radius: 3px;
    color: $white;
    cursor: pointer;

    @include m(primary) {
        border: 1px solid $green;
        color: $green;
        background: $white;
    }

    @include m(secondary) {
        background: $green;
    }
}