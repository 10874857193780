// ==========================================================================
// Config
// Define variables, maps and fonts at this partial.
// ==========================================================================

$domain-name: "www.domain-name.com";

//Text
$base__font-size: 16px;
$base__line: 24px;

//Font Stacks
@font-face {
  font-family: "Circular Pro";
  src: url("../../assets/fonts/CircularPro-Book.eot");
  src: url("../../assets/fonts/CircularPro-Book.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/CircularPro-Book.woff2") format("woff2"),
    url("../../assets/fonts/CircularPro-Book.woff") format("woff"),
    url("../../assets/fonts/CircularPro-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Circular Pro";
  src: url("../../assets/fonts/CircularPro-Medium.eot");
  src: url("../../assets/fonts/CircularPro-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/CircularPro-Medium.woff2") format("woff2"),
    url("../../assets/fonts/CircularPro-Medium.woff") format("woff"),
    url("../../assets/fonts/CircularPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Circular Pro";
  src: url("../../assets/fonts/CircularPro-Bold.eot");
  src: url("../../assets/fonts/CircularPro-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/CircularPro-Bold.woff2") format("woff2"),
    url("../../assets/fonts/CircularPro-Bold.woff") format("woff"),
    url("../../assets/fonts/CircularPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

$font-family-sans-fallback: "Arial", sans-serif;
$font-family-sans: "Circular Pro", "Helvetica Neue", "Helvetica",
  $font-family-sans-fallback;
$font-family-serif: "Georgia", "Cambria", "Times New Roman", "Times", serif;
$font-family-monospace: "Inconsolata", "Menlo", "Consolas",
  "Bitstream Vera Sans Mono", "Courier", monospace;
$font-family--primary: $font-family-sans;
$font-family--secondary: $font-family-sans;

//Colors
$white: #fff;
$black: #000;
$gray: #c7c7c7;
$red: #d24d57;
$blue: #3a539b;
$purple: #784cfa;
$light-purple: #f1edff;
$blue: #000065;
$light-blue: #009bde;
$yellow: #f7ca18;
$green: #36d7b7;

$background: $white;
$light-text: #6a777d;
$dark-text: #273134;
$primary: $purple;
$light-primary: $light-purple;
$secondary: $blue;

//Message Colors
$error: lighten($red, 5%) !default;
$success: $green !default;
$edit: $yellow !default;

//Breakpoints
$sm-device: 576px;
$md-device: 768px;
$lg-device: 992px;
$xl-device: 1200px;

//UI Colors
$ui-colors: (
  default: $blue,
  success: $success,
  error: $error,
  warning: $edit,
);

//Color Palette
$palettes: (
  gray-shades: (
    base: $gray,
    dark: darken($gray, 20%),
    light: lighten($gray, 20%),
  ),
  blue-shades: (
    base: $blue,
    dark: darken($blue, 20%),
    light: lighten($blue, 20%),
  ),
);

//Font size variables
$default-font-size: $base__font-size - 2px;
$small-font-size: $default-font-size - 2px;
$lg-font-size: $default-font-size + 2px;

//Heading font size vairables
$heading-line-height: 28px !default;
$head1-font-size: 26px !default;
$head2-font-size: $head1-font-size - 4px !default;
$head3-font-size: $head1-font-size - 8px !default;

//Color Usage
$primary-color: $white;
$secondary-color: $black;
$accent-color: $gray;
$shadow-color: rgba($black, 0.2);
$border-color: $gray;
$color-text: $black;
$color-link: $blue;

//Path to Assets
$path--rel: "../images";
